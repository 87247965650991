
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {
  ActionTypes as OffersActionTypes,
  GetterTypes as OffersGetterTypes
} from '@/store/offers/types';
import Offer from '@/models/Offer';
import PwrVue from '@/components/PwrVue';
import CompetitionsResultsItem from '@/views/CompetitionsResults/Cometitions/CompetitionResultItem.vue';
import OffersView from '@/views/Offers/OffersView.vue';
import PublicJobOffers from '@/views/Offers/Offers/PublicJobOffers.vue';
import OpSplitLayout from '@/views/Layouts/OpSplitLayout.vue';
import OfferFilters from '@/views/Offers/OfferFilters/OfferFilters.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import OpOfferPagination from '@/components/Op/Offers/OpOfferPagination.vue';
import OpNoResults from '@/components/Op/Offers/OpNoResults.vue';
import OpOffersSkeletonLoader from '@/components/Op/Offers/OpOffersSkeletonLoader.vue';
import OpSearchInput from '@/components/Op/OpSearchInput.vue';

const offersNamespace = 'offers';

@Component({
  components: {
    OpOffersSkeletonLoader,
    OpNoResults,
    OpPagination: OpOfferPagination,
    PwrCardTitle,
    PwrCard,
    JobOffers: OffersView,
    CompetitionItem: CompetitionsResultsItem,
    PublicJobOffers,
    OpSplitLayout,
    OpSearchInput,
    OfferFilters,
    PwrBtn
  }
})
export default class CompetitionsResultsView extends PwrVue {
  @Prop({ default: () => ['type', 'state', 'attributes'] }) forbiddenFilters!: string[];

  // Offers vuex
  @Action(OffersActionTypes.FETCH_CONCLUDED_OFFERS, { namespace: offersNamespace })
  fetchConcludedOffers!: () => void;

  @Getter(OffersGetterTypes.GET_PAGINATED_ITEMS, { namespace: offersNamespace }) offers!: Offer[];

  @Action(OffersActionTypes.SEARCH, { namespace: offersNamespace })
  search!: (query: string | undefined) => void;

  // Fixes empty list when changing tab
  @Action(OffersActionTypes.CLEAR_SEARCH, { namespace: offersNamespace })
  clearSearch!: () => void;

  private offersLoading = false;
  private filtersUpdate = false;

  protected async mounted(): Promise<void> {
    this.resetAfkTimer();

    this.offersLoading = true;
    await this.fetchConcludedOffers();
    await this.clearSearch();
    this.offersLoading = false;
  }

  private onQueryChange(query: string) {
    this.search(query);
  }
}
