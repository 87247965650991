
import { Component, Prop } from 'vue-property-decorator';
import Offer from '@/models/Offer';
import { PwrFile } from '@/components/Pwr/PwrFiles/types';
import PwrVue from '@/components/PwrVue';
import OpFilter from '@/components/Op/Filters/OpFilter.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import download from '@/assets/pwr/icons/download.svg';

@Component({
  components: { OpFilter, PwrCardTitle, PwrCard, PwrBtn }
})
export default class CompetitionsResultsItem extends PwrVue {
  @Prop() offer!: Offer;

  get downloadIcon() {
    return download;
  }

  get file(): PwrFile | null {
    if (this.offer.files) {
      const competitionResultFile: PwrFile[] = this.offer.files.filter(
        (file: PwrFile) => file.source === 'resolution'
      );

      if (competitionResultFile.length > 0) {
        return competitionResultFile[0];
      }
    }

    return null;
  }
}
